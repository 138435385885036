<template>
  <div class="page_join" id="join_list">
<!--    <el-backtop target=".page_join"></el-backtop>-->
    <div class="banner">
    </div>
    <div class="button_group">
      <el-button type="danger" round @click="goAnchor('#jmys')">合作优势</el-button>
      <el-button type="danger" round @click="goAnchor('#jmtj')">合作条件</el-button>
      <el-button type="danger" round @click="goAnchor('#jmfy')">合作费用</el-button>
      <el-button type="danger" round @click="goAnchor('#jmlc')">合作流程</el-button>
      <el-button type="danger" round @click="goAnchor('#cjwt')">合作常见问题</el-button>
      <el-button type="danger" round @click="goAnchor('#jmyz')">选址五原则</el-button>
      <el-button type="danger" round @click="to_form('/consultation_information/edit')">合作留言</el-button>
    </div>
    <div class="jm_content">
      <div id="jmys"></div>
      <div id="jmtj"></div>
      <div id="jmfy"></div>
      <div id="jmlc"></div>
      <div id="cjwt"></div>
      <div id="jmyz"></div>
    </div>
    <div id="jm_form">
      <!--      <edit></edit>-->
    </div>
    <div class="jmly">
      <router-link to="/consultation_information/edit">
        <div class="jmly_to"></div>
      </router-link>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/page.js";
import edit from "@/views/consultation_information/edit.vue";

export default {
  mixins: [mixin],
  components: {
    edit,
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {
    goAnchor(selector) {
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth"
      });
    }
  },
};
</script>

<style scoped>
.page_join {
  min-height: 750px;
  //overflow-x: hidden;
}

.button_group {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/images/numbg.jpg") ;
}

.button_group > a {
  margin-right: 10px;
}

.banner {
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/jmbg.jpg") no-repeat;
  background-size: 100% 100%;
}

.jm_content {
  width: 100%;
  background: url("../../assets/images/numbg.jpg");
}

#jmys {
  width: 70%;
  height: 110vh;
  background: url("../../assets/images/join/jmys.jpg") no-repeat;
  background-size: 100% 100%;
  margin: auto;
}

#jmtj {
  width: 70%;
  height: 100vh;
  background: url("../../assets/images/join/jmtj.jpg") no-repeat;
  background-size: 100% 100%;
  margin: auto;
}

#jmfy {
  width: 70%;
  height: 100vh;
  background: url("../../assets/images/join/jmfy.jpg") no-repeat;
  background-size: 100% 100%;
  margin: auto;
}

#jmlc {
  width: 85%;
  height: 90vh;
  background: url("../../assets/images/join/jmlc.jpg") no-repeat;
  background-size: 100% 100%;
  margin: auto;
}

#cjwt {
  width: 50%;
  height: 120vh;
  background: url("../../assets/images/join/cjwt.jpg") no-repeat;
  background-size: 100% 100%;
  margin: auto;
}

#jmyz {
  width: 90%;
  height: 630px;
  background: url("../../assets/images/join/jmyz.jpg") no-repeat;
  background-size: 100% 100%;
  margin: auto;
}

.jmly {
  height: 68px !important;
  width: 130px;
  z-index: 11111112;
  top: 20%;
  right: 0px;
  position: fixed;
  margin-top: -34px;
  margin-left: 0px;
}

.jmly_to {
  height: 100%;
  width: 100%;
  background: url("../../assets/images/join/hzzx.png") no-repeat;
  background-size: 100% 100%;
}
.el-button--danger {
  background-color: #e70012;;
}
.el-button.is-round {
  padding: 15px 40px;
  font-weight: bolder;
  font-size: larger;
}
@media (max-width: 1081px) {
  .jmly_to {
    height: 50%;
    width: 50%;
    background: url("../../assets/images/join/hzzx.png") no-repeat;
    background-size: 100% 100%;
  }

  .jmly {
    height: 68px !important;
    width: 130px;
    z-index: 11111112;
    top: 20%;
    right: -45px;
    position: fixed;
    margin-top: -34px;
    margin-left: 0px;
  }
  .banner {
    width: 100%;
    height: 40vh;
    background: url("../../assets/images/jmbg.jpg") no-repeat;
    background-size: 100% 100%;
  }
  .button_group {
    width: 100%;
    display: ruby;
    background: url("../../assets/images/numbg.jpg");
  }
  .button_group > a {
    margin-right: 10px;
  }
  #jmys {
    width: 100%;
    height: 60vh;
    background: url("../../assets/images/join/jmys.jpg") no-repeat;
    background-size: 100% 100%;
    margin: auto;
  }
  #jmtj {
    width: 100%;
    height: 60vh;
    background: url("../../assets/images/join/jmtj.jpg") no-repeat;
    background-size: 100% 100%;
    margin: auto;
  }

  #jmfy {
    width: 100%;
    height: 50vh;
    background: url("../../assets/images/join/jmfy.jpg") no-repeat;
    background-size: 100% 100%;
    margin: auto;
  }

  #jmlc {
    width: 100%;
    height: 50vh;
    background: url("../../assets/images/join/jmlc.jpg") no-repeat;
    background-size: 100% 100%;
    margin: auto;
  }

  #cjwt {
    width: 100%;
    height: 60vh;
    background: url("../../assets/images/join/cjwt.jpg") no-repeat;
    background-size: 100% 100%;
    margin: auto;
  }

  #jmyz {
    width: 100%;
    height: 40vh;
    background: url("../../assets/images/join/jmyz.jpg") no-repeat;
    background-size: 100% 100%;
    margin: auto;
  }

}

</style>
